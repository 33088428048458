<template>
  <b-card>
    <!-- form -->
    <validation-observer
      ref="refFormObserver"
    >
      <b-form class="mt-2">
        <b-row>
          <b-col md="6">
            <i2-form-password
              v-model="model.password"
              label="Senha"
              name="password"
              rules="required|password|min:6"
            />
          </b-col>
          <b-col md="6">
            <i2-form-password
              v-model="model.password_confirmation"
              label="Informe novamente a Senha"
              name="confirme a senha"
              rules="required|confirmed:Senha"
            />
          </b-col>
          <b-col
            cols="12"
            class="text-right"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2"
              @click="onSubmit"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              Alterar senha
            </b-button>

          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import I2FormPassword from '@/components/i2/forms/elements/I2FormPassword.vue'
import { ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    I2FormPassword,
    ValidationObserver,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      model: {
        password: '',
        password_confirmation: '',
      },
    }
  },

  methods: {
    onSubmit() {
      this.$refs.refFormObserver.validate().then(async success => {
        if (success) {
          const response = await this.$http.put('me/password', this.model)

          if (response.error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
            return false
          }
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Senha alterada com sucesso',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        }
        this.model = {
          password: '',
          password_confirmation: '',
        }
        this.$refs.refFormObserver.reset()
        return false
      })
    },
  },
}
</script>
